import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Alert, Button, CardTitle, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import ReactstrapFormikInput from '../../components/forms/ReactstrapFormikInput';
import useToken from '../../helpers/useToken';
import UserLayout from '../../layout/UserLayout';
import { loginUser } from '../../redux/auth/actions';
import './login-styles.css';

const Login = ({ onStateChange, loginUser, loading, error, history }) => {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { state } = useLocation();
  const { setToken } = useToken();

  const goToSignUp = () => {
    history.push('/user/register');
  };

  const goToConfirm = () => {
    history.push('/user/confirm');
  };

  const goToForgotPassword = () => {
    history.push('/user/forgot-password');
  };

  function attemptLogin(values) {
    loginUser(values, onStateChange)
      .then(token => {
        setToken(token);
      })
      .then(() => setRedirectToReferrer(true))
      .catch(err => {
        if (err.code === 'UserNotConfirmedException') {
          goToConfirm(values);
        }
      });
  }

  if (redirectToReferrer === true) {
    return <Redirect to={state?.from || '/app'} />;
  }

  return (
    <div className='login-container'>
      <UserLayout>
        <Row className='split-layout'>
          {/* Left Side - Content */}
          <Colxx lg='7' md='6' className='left-side'>
            <div className='left-content'>
              <h1 style={{ color: 'white' }}>LastRecord</h1>
              <p>Public Safety Task Books and Performance Reviews made easy.</p>
              {/* Add any additional content, images, or illustrations here */}
            </div>
          </Colxx>

          {/* Right Side - Login Form */}
          <Colxx lg='5' md='6' className='right-side'>
            <div className='login-form-container'>
              <div className='login-form-wrapper'>
                <CardTitle className='login-title'>Sign in to LastRecord</CardTitle>
                <Formik
                  initialValues={{ email: '', password: '' }}
                  onSubmit={values => attemptLogin(values)}
                >
                  {props => (
                    <Form>
                      <div className='form-group'>
                        <label>Email (case sensitive)</label>
                        <Field
                          component={ReactstrapFormikInput}
                          type='email'
                          name='email'
                          required
                        />
                      </div>

                      <div className='form-group'>
                        <label>Password</label>
                        <Field component={ReactstrapFormikInput} type='password' name='password' />
                      </div>

                      {error && (
                        <Alert color='danger' className='error-alert'>
                          {error.error}
                        </Alert>
                      )}

                      <Button className='login-button' size='lg' type='submit' disabled={loading}>
                        Sign In
                      </Button>

                      <div className='forgot-password'>
                        <a href='#' onClick={e => goToForgotPassword(props.values)}>
                          Forgot password?
                        </a>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className='login-footer'>
                By logging in, you agree to our{' '}
                <a href='https://www.lastrecord.com/terms'>terms of service</a> and{' '}
                <a href='https://www.lastrecord.com/privacy'>privacy policy</a>.
              </div>
            </div>
          </Colxx>
        </Row>
      </UserLayout>
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error
});

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (values, onStateChange) =>
      dispatch(loginUser(values.email, values.password, onStateChange))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
